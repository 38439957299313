

































import { KPIBus, KPIEvents } from "@/events/kpiBus";
import db from "@/firebase/db";
import DBHelper from "@/tscript/dbHelper";
import Vue from "vue";

export default Vue.extend({
  props: {
    inCascade: {
      type: Boolean,
      default: false,
    },
    title: {
      type: [String, Number],
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    remove: {
      type: Function,
      required: false,
    },
  },

  methods: {
    deleteKPI() {
      new DBHelper(db)
        .deleteData(
          `clients/${this.$store.state.userData.client_id}/apps/${this.$route.params.id}/kpis`,
          this.id
        )
        .then(() => {
          KPIBus.$emit(KPIEvents.deleteKpi, this.id);
        });
    },
    show(e) {
      e.preventDefault();
      this.showMenu = false;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },
  },

  data() {
    return {
      c: true,
      showMenu: false,
    };
  },
});
