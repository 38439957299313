import loggerHelper from "../loggerHelper/";
import _ from "lodash";

export default class DBHelper {
  db: any;
  constructor(db: any) {
    this.db = db;
  }
  async setDataToCollection(collection: string, id: string, data: any) {
    return await this.db
      .collection(collection)
      .doc(id)
      .set(data)
      .catch((e: any) => {
        const error =
          "setDataToCollection - " + collection + " - " + id + " - " + e;
        loggerHelper.report(error);
      });
  }

  async addDataToCollection(collection: string, data: any) {
    return await this.db
      .collection(collection)
      .add(data)
      .catch((e: any) => {
        const error = "addDataToCollection - " + collection + " - " + e;
        loggerHelper.report(error);
      });
  }

  async updateDataToCollection(collection: string, id: string, data: any) {
    return await this.db
      .collection(collection)
      .doc(id)
      .update(data)
      .catch((e: any) => {
        const error =
          "updateDataToCollection - " + collection + " - " + id + " - " + e;
        loggerHelper.report(error);
      });
  }

  async deleteData(collection: string, id: string) {
    return await this.db
      .collection(collection)
      .doc(id)
      .delete()
      .catch((e: any) => {
        const error = "deleteData - " + collection + " - " + id + " - " + e;
        loggerHelper.report(error);
      });
  }

  async batchUpdate(collection: string, data: any) {
    const array = await this.getAllDataFromCollection(collection);
    const batches = _.chunk(array, 500).map((chunk) => {
      const batch = this.db.batch();
      chunk.forEach((obj, i) => {
        const docRef = this.db.collection(collection).doc(obj.id);
        batch.update(docRef, data);
      });
      return batch.commit();
    });
    return await Promise.all(batches);
  }

  async getAllDataFromCollectionBetweenDates(
    collection: string,
    whatIs: string,
    startDate: Date,
    endDate: Date
  ) {
    const returnArray: Array<any> = [];
    const docRef = this.db.collection(collection);
    await docRef
      .where(whatIs, ">", startDate)
      .where(whatIs, "<", endDate)
      .get()
      .then(function (snapshot: any) {
        snapshot.forEach(function (doc: any) {
          if (doc && doc.exists && doc.data && doc.id) {
            const result = doc.data();
            result["id"] = doc.id;
            returnArray.push(result);
          }
        });
      })
      .catch((e: any) => {
        const error =
          "getAllDataFromCollectionBetweenDates - " +
          collection +
          " - " +
          whatIs +
          " - " +
          e;
        loggerHelper.report(error);
      });
    return returnArray;
  }

  async getAllDataFromCollectionWithWhereIn(
    collection: string,
    whatIs: string,
    equalTo: Array<any>
  ) {
    const returnArray: Array<any> = [];
    const docRef = this.db.collection(collection);
    await docRef
      .where(whatIs, "in", equalTo)
      .get()
      .then(function (snapshot: any) {
        snapshot.forEach(function (doc: any) {
          if (doc && doc.exists && doc.data && doc.id) {
            const result = doc.data();
            result["id"] = doc.id;
            returnArray.push(result);
          }
        });
      })
      .catch((e: any) => {
        const error =
          "getAllDataFromCollectionWithWhereIn - " +
          collection +
          " - " +
          whatIs +
          " - " +
          e;
        loggerHelper.report(error);
      });
    return returnArray;
  }

  async getAllDataFromCollectionWithWhere(
    collection: string,
    whatIs: string,
    equalTo: any
  ) {
    const returnArray: Array<any> = [];
    const docRef = this.db.collection(collection);
    await docRef
      .where(whatIs, "==", equalTo)
      .get()
      .then(function (snapshot: any) {
        snapshot.forEach(function (doc: any) {
          if (doc && doc.exists && doc.data && doc.id) {
            const result = doc.data();
            result["id"] = doc.id;
            returnArray.push(result);
          }
        });
      })
      .catch((e: any) => {
        const error =
          "getAllDataFromCollectionWithWhere - " +
          collection +
          " - " +
          whatIs +
          " - " +
          equalTo +
          " - " +
          e;
        loggerHelper.report(error);
      });
    return returnArray;
  }

  async getAllDataFromCollection(collection: string) {
    const returnArray: Array<any> = [];
    const docRef = this.db.collection(collection);
    await docRef
      .get()
      .then(function (snapshot: any) {
        snapshot.forEach(function (doc: any) {
          if (doc && doc.exists && doc.data && doc.id) {
            const result = doc.data();
            result["id"] = doc.id;
            returnArray.push(result);
          }
        });
      })
      .catch((e: any) => {
        const error = "getAllDataFromCollection - " + collection + " - " + e;
        loggerHelper.report(error);
      });
    return returnArray;
  }

  async getDocFromCollection(collection: string, docId: string) {
    if (docId) {
      const docRef = this.db.collection(collection);
      const result = await docRef
        .doc(docId)
        .get()
        .then(function (doc: any) {
          if (doc && doc.exists && doc.data && doc.id) {
            const resultQuery: any = doc.data();
            resultQuery["id"] = doc.id;
            return resultQuery;
          }
        })
        .catch((e: any) => {
          const error =
            "getDocFromCollection - " + collection + " - " + docId + " - " + e;
          loggerHelper.report(error);
        });
      return result;
    }
  }

  async getCollectionOrderDate(collection: string, dateProp: string) {
    const returnArray: Array<any> = [];
    const docRef = this.db.collection(collection).orderBy(dateProp, "desc");
    await docRef
      .get()
      .then(function (snapshot: any) {
        snapshot.forEach(function (doc: any) {
          if (doc && doc.exists && doc.data && doc.id) {
            const result = doc.data();
            result["id"] = doc.id;
            returnArray.push(result);
          }
        });
      })
      .catch((e: any) => {
        const error = "getCollectionOrderDate - " + collection + " - " + e;
        loggerHelper.report(error);
      });
    return returnArray;
  }

  async getDocFromCollectionWithWhere(
    collection: string,
    what: string,
    isWhat: string
  ) {
    let docRef = this.db.collection(collection);
    docRef = docRef.where(what, "==", isWhat);
    const result = await docRef
      .get()
      .then(function (snapshot: any) {
        if (!snapshot.empty) {
          const result = snapshot.docs[0].data();
          result["id"] = snapshot.docs[0].id;
          return result;
        } else {
          return false;
        }
      })
      .catch((e: any) => {
        const error =
          "getDocFromCollectionWithWhere - " + collection + " - " + e;
        loggerHelper.report(error);
      });
    return result;
  }

  async getAllDataFromCollectionWithWhereArray(
    collection: string,
    arrayWhere: Array<any>
  ) {
    const returnArray: Array<any> = [];
    let docRef = this.db.collection(collection);
    for (const prop in arrayWhere) {
      docRef = docRef.where(prop, "==", arrayWhere[prop]);
    }

    await docRef
      .get()
      .then(function (snapshot: any) {
        snapshot.forEach(function (doc: any) {
          if (doc && doc.exists && doc.data && doc.id) {
            const result = doc.data();
            result["id"] = doc.id;
            returnArray.push(result);
          }
        });
      })
      .catch((e: any) => {
        const error =
          "getAllDataFromCollectionWithWhereArray - " + collection + " - " + e;
        loggerHelper.report(error);
      });

    return returnArray;
  }
  async getAllDataFromCollectionWithAll(collection: string, constraints: any) {
    let returnArray: Array<any> = [];
    let query = this.db.collection(collection);
    const { where, orderBy, limit } = constraints || {};
    if (where) {
      where.forEach((cond: any) => {
        query = query.where(cond.field, cond.compare, cond.value);
      });
    }
    if (orderBy) {
      orderBy.forEach((cond: any) => {
        query = query.orderBy(cond.field, cond.direction || "asc");
      });
    }
    if (limit) {
      query = query.limit(limit);
    }
    await query
      .get()
      .then((snapshot: any) => {
        returnArray = snapshot.docs.map((doc: any) => ({
          ...(doc || {}).data(),
          id: doc.id,
        }));
      })
      .catch((e: any) => {
        loggerHelper.report(
          "getAllDataFromCollectionWithAll - " +
            collection +
            " - " +
            JSON.stringify(constraints) +
            " - " +
            e
        );
      });
    return returnArray;
  }

  async getReference(documentReference) {
    const res = await documentReference.get();
    const data = res.data();
    if (data && documentReference.id) {
      data.id = documentReference.id;
    }
    return data;
  }

  async getViaReference(paths: any[]) {
    const res = [] as any;
    return Promise.all(
      paths.map(async (path) => {
        return await this.getReference(path);
      })
    );
  }
}
