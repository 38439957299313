



































import Vue from "vue";

export default Vue.extend({
  props: {
    color: {
      type: String,
      default: "#D4D2FF",
    },
    id: {
      type: String,
      required: true,
    },
    nodeData: {
      type: Object,
    },
    name: {
      type: String,
    },
    textColor: {
      type: String,
      default: "black",
    },
    value: {
      type: [Number, String],
    },
    unit: {
      type: String,
    },
  },
  data() {
    return {};
  },
  computed: {
    kpisStatus(): boolean | undefined {
      if (
        this.nodeData?.kpisStatus &&
        this.nodeData?.kpisStatus[this.id] !== undefined
      )
        return this.nodeData?.kpisStatus[this.id] === true ? true : false;
      else return undefined;
    },
  },
  methods: {},
  mounted() {},
});
