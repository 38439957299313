import db from "@/firebase/db";
import fb from "firebase/app";
import DbHelper from "../dbHelper";

export const downloadBlob = (
  content: any,
  filename: string,
  contentType: any
) => {
  // Create a blob
  const blob = new Blob([content], { type: contentType });
  const url = URL.createObjectURL(blob);

  // Create a link to download it
  const pom = document.createElement("a");
  pom.href = url;
  pom.setAttribute("download", filename);
  pom.click();
};

export const stringToColor = (string: string) => {
  let hash = 0;
  let i;
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }

  return color;
};

// Get Translation
export const getT = (t: any, id: string) => {
  let translation = "";
  if (id) {
    if (t?.$root?.$t) {
      if (t.$root.$t(id)) {
        translation = t.$root.$t(id).toString();
      }
    }
  }
  return translation;
};

export const normalizeString = (
  s: any,
  transform = (s: any) => s.toLocaleLowerCase()
) => {
  let normalized = transform(s);

  try {
    normalized = normalized.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  } catch (e) {
    // just ignore
  }

  return normalized;
};

export const datesAreOnSameDay = (first: Date, second: Date) =>
  first.getMonth() === second.getMonth() &&
  first.getDate() === second.getDate();

export const removeChars = (text: string) => {
  let result = "";
  if (text) {
    result = text
      .replace("á", "a")
      .replace("à", "a")
      .replace("â", "a")
      .replace("ä", "a")
      .replace("é", "e")
      .replace("è", "e")
      .replace("ê", "e")
      .replace("ë", "e")
      .replace("ì", "i")
      .replace("í", "i")
      .replace("î", "i")
      .replace("ï", "i")
      .replace("ó", "o")
      .replace("ò", "o")
      .replace("ö", "o")
      .replace("ô", "o")
      .replace("ú", "u")
      .replace("ù", "u")
      .replace("ü", "u")
      .replace("û", "u")
      .replace("ç", "c");
  }
  return result;
};

export const linkify = function (text: string) {
  const urlPattern =
    /(?:https?:)?\/\/(?:(?:[\w-]+\.)+[\w/#@~.-]*)(?:\?(?:[\w&=.!,;$#%-]+)?)?/gi;
  return (text || "").replace(urlPattern, function (url) {
    return '<a target="_BLANK" href="' + url + '">' + url + "</a>";
  });
};

export const truncate = function (str: string, max: number, sep: string) {
  // Default to 10 characters
  max = max || 10;

  const len = str?.length;
  if (len > max) {
    // Default to elipsis
    sep = sep || "...";

    const seplen = sep.length;

    // If seperator is larger than character limit,
    // well then we don't want to just show the seperator,
    // so just show right hand side of the string.
    if (seplen > max) {
      return str.substr(len - max);
    }

    // Half the difference between max and string length.
    // Multiply negative because small minus big.
    // Must account for length of separator too.
    const n = -0.5 * (max - len - seplen);

    // This gives us the centerline.
    const center = len / 2;

    const front = str.substr(0, center - n);
    const back = str.substr(len - center + n); // without second arg, will automatically go to end of line.

    return front + sep + back;
  }

  return str;
};

/**
 * Fancy ID generator that creates 20-character string identifiers with the following properties:
 *
 * 1. They're based on timestamp so that they sort *after* any existing ids.
 * 2. They contain 72-bits of random data after the timestamp so that IDs won't collide with other clients' IDs.
 * 3. They sort *lexicographically* (so the timestamp is converted to characters that will sort properly).
 * 4. They're monotonically increasing.  Even if you generate more than one in the same timestamp, the
 *    latter ones will sort after the former ones.  We do this by using the previous random bits
 *    but "incrementing" them by 1 (only in the case of a timestamp collision).
 */
export const generatePushID = (function () {
  // Modeled after base64 web-safe chars, but ordered by ASCII.
  const PUSH_CHARS =
    "-0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ_abcdefghijklmnopqrstuvwxyz";

  // Timestamp of last push, used to prevent local collisions if you push twice in one ms.
  let lastPushTime = 0;

  // We generate 72-bits of randomness which get turned into 12 characters and appended to the
  // timestamp to prevent collisions with other clients.  We store the last characters we
  // generated because in the event of a collision, we'll use those same characters except
  // "incremented" by one.
  const lastRandChars: Array<any> = [];

  return function () {
    let now = new Date().getTime();
    const duplicateTime = now === lastPushTime;
    lastPushTime = now;
    let i = 0;
    const timeStampChars = new Array(8);
    for (i = 7; i >= 0; i--) {
      timeStampChars[i] = PUSH_CHARS.charAt(now % 64);
      // NOTE: Can't use << here because javascript will convert to int and lose the upper bits.
      now = Math.floor(now / 64);
    }
    if (now !== 0)
      throw new Error("We should have converted the entire timestamp.");

    let id = timeStampChars.join("");

    if (!duplicateTime) {
      for (i = 0; i < 12; i++) {
        lastRandChars[i] = Math.floor(Math.random() * 64);
      }
    } else {
      // If the timestamp hasn't changed since last push, use the same random number, except incremented by 1.
      for (i = 11; i >= 0 && lastRandChars[i] === 63; i--) {
        lastRandChars[i] = 0;
      }
      lastRandChars[i]++;
    }
    for (i = 0; i < 12; i++) {
      id += PUSH_CHARS.charAt(lastRandChars[i]);
    }
    if (id.length != 20) throw new Error("Length should be 20.");

    return id;
  };
})();
export const getRightWhere = (user: any) => {
  const arrayWhere: any = {
    client_id: user?.client_id,
  };
  // if (user?.role?.startsWith("SITE") && user?.site_id) {
  //   arrayWhere["site_id"] = user?.site_id;
  // }
  return arrayWhere;
};
