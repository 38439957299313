const cascadeStore = {
  state: () => ({
    editionMode: false,
    searchMode: false,
  }),

  actions: {},

  mutations: {
    toggleEdition(state: any) {
      state.editionMode = !state.editionMode;
    },
    setEdition(state: any, payload: boolean) {
      state.editionMode = payload;
    },
    toggleSearchMode(state: any, payload: boolean) {
      state.searchMode = payload;
    },
  },
};

export default cascadeStore;
