/* eslint-disable no-console*/
/* import StackdriverErrorReporter from "stackdriver-errors-js";

const stackDriverReporter = new StackdriverErrorReporter();

/* stackDriverReporter.start({
  key:
    process.env.VUE_APP_BROWSER_API ||
    "AIzaSyDsKIlwCJNEXUx4bbbyg6lS4bcPyDOPv9s",
  projectId:
    process.env.NODE_ENV === "production"
      ? "niagara-prod-55362"
      : "niagara-staging-d7fc7",
  version: process.env.VUE_APP_VERSION,
  disabled: process.env.VUE_APP_BROWSER_API ? false : true,
  service: "niagara-webapp",
}); */

export const onError = (error: Error, details) => {
  console.error(error, details);
  // stackDriverReporter.report(error);
};

export const onWarn = (warning: Error, details) => {
  console.warn(warning, details);
  // stackDriverReporter.report(warning);
};

export const setLogUser = (userId: string) => {
  // stackDriverReporter.setUser(userId);
};
