import Vue from "vue";
import VueAcl from "vue-browser-acl";
import Acl from "browser-acl";

export default function initAcl(user: any, router: any, state: any) {
  Vue.use(
    VueAcl,
    user,
    (acl: Acl) => {
      acl.rule("admin", (user: any) => user && user.role === "CLIENT_ADMIN");
      acl.rule("super", (user: any) => user && user.role === "SUPERADMIN");
      acl.rule(
        "user",
        (user: any) =>
          user &&
          (user.role === "CLIENT_ADMIN" ||
            user.role === "SUPERADMIN" ||
            user.role === "USER")
      );

      // client based roles
      acl.rule(
        "dc",
        (user: any) => user && user.client.parameters.data_catalog.active
      );
      acl.rule(
        "systems",
        (user: any) => user && user.client.parameters.systems.active
      );
      acl.rule(
        "wf",
        (user: any) => user && user.client.parameters.workflows.active
      );
    },
    { router }
  );
}
