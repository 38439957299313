

































































































































































































































































import Vue from "vue";
import { KPIBus, KPIEvents } from "@/events/kpiBus";
import { conditionsCheck } from "@/utils";
import NumberCard from "./NumberCard.vue";
import { find } from "lodash";
import { IFormula } from "@/types/apps";

export default Vue.extend({
  components: { NumberCard },
  data() {
    return {
      deleteDialog: false,
    };
  },
  props: {
    remove: {
      type: Function,
    },
    node: {
      type: Object,
    },
    title: {
      type: [String, Number, Array],
      default: null as any,
    },
    description: {
      type: [String, Number],
    },
    unit: {
      type: String,
    },
  },

  computed: {
    edition(): boolean {
      return this.$store.state.cascadeStore.editionMode;
    },

    calculatedValues(): IFormula[] | undefined {
      const formulae: any[] =
        this.$store.state.appsStore.currentApp.settings.formula;

      if (this.title) {
        if (Array.isArray(this.title)) {
          const val = this.title.map((v) => {
            const formula = find(formulae, { id: v.name });
            return { ...v, ...formula };
          });
          return val;
        } else {
          return [this.title];
        }
      }
      return undefined;
    },

    objMet(): string {
      if (this.node.conditions.length > 0) {
        /* const meetsConditions = conditionsCheck(
          this.node.conditions,
          this.node,
          this.node.appData
        );
        if (meetsConditions === true)  */ return "GOOD";
        //return "FAILED";
      }

      return "NO_CONDITION";
    },
  },
  methods: {
    removeNode(node: any) {
      this.deleteDialog = true;
    },

    deleteNode() {
      this.deleteDialog = false;
      this.remove();
    },

    viewKPIDetail() {
      history.pushState({}, "", `${this.$route.path}?kpi=${this.node.id}`);
      KPIBus.$emit(KPIEvents.openKpi, this.node);
    },
  },
  mounted() {},
});
