











































































































import Vue from "vue";
import fb from "firebase/app";
import TheSideBar from "@/components/TheSideBar.vue";
import TheNotificationSideBar from "@/components/TheNotificationSideBar.vue";

import { VERSION } from "@/config/version";
import db from "./firebase/db";

export default Vue.extend({
  name: "App",
  components: {
    TheSideBar,
    TheNotificationSideBar,
    // BaseToaster
  },
  data: () => ({
    displaySidebar: true,
    userData: null,
    version: VERSION,
    updateExists: false,
    registration: null as any,
    refreshing: false,
    year: "",
    notifications: new Array(),
  }),
  methods: {
    hotReload() {
      this.updateExists = false;
      if (!this.registration || !this.registration.waiting) return;
      // Send message to SW to skip the waiting and activate the new SW
      this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
    },
    async getNotifications() {
      await db
        .collection(`alerts/${this.$store.state.userId}/notifications`)
        .orderBy("timestamp", "desc")
        .onSnapshot((qSnap) => {
          this.notifications = [];
          qSnap.forEach((doc) => {
            this.notifications.push({
              id: doc.id,
              ...doc.data(),
            });
            this.$store.commit(
              "setNotificationsCount",
              this.notifications.filter((n: any) => n.read === false).length
            );
          });
        });
    },
    updateAvailable(event) {
      this.registration = event.detail;
      this.updateExists = true;
    },
    openOnProd() {
      // get url route
      const sub = new URL(window.location.href).pathname.split("/")[1] || "";
      this.$toasted.info(`Redirection sur app.niagara.tech/${sub}`);
      window.open(`https://app.niagara.tech/${sub}`, "_blank");
    },
  },
  computed: {
    displayNotificationbar() {
      return this.$store.state.displayAlertBar;
    },
    loading() {
      return this.$store.state.isAppLoading;
    },
    bGLoading() {
      return this.$store.state.isBackgroundLoading;
    },
    bGLoadingText() {
      return this.$store.state.backgroundLoadingText;
    },
    isStaging() {
      const subdomain = window.location.hostname.split(".")[0];
      if (["staging", "localhost"].includes(subdomain)) return true;
      else return false;
    },
  },
  async created() {
    this.year = new Date().getFullYear().toString();
    if (navigator?.language && navigator?.language.length >= 2) {
      const locale_iso = navigator.language.substring(0, 2);
      this.$i18n.locale = locale_iso;
      // this.$store.commit("setLocale", locale_iso);
    }
    const user = await fb.auth().currentUser;
    const router: any = this.$router;
    const currentPage = router?.history.current.name;
    if (!user) {
      if (currentPage !== "/login") {
        this.displaySidebar = false;
      }
    } else {
      user.getIdTokenResult().then((idTokenResult) => {
        this.$store.dispatch("reloadUserAction", user);
        this.$store.dispatch("bindNotifications");
        this.displaySidebar = true;
      });
    }
    // Hide bar when logout
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "setUserId") {
        if (state.userId) {
          this.displaySidebar = true;
          this.getNotifications();
        } else {
          this.displaySidebar = false;
        }
      }
      if (mutation.type === "setUserData") {
        if (state.userData) {
          this.userData = state.userData;
          this.$rollbar.configure({
            payload: {
              person: {
                id: state.userData.id,
                username: `${state.userData.first_name || ""} ${
                  state.userData.last_name || ""
                }`,
                client: state.userData.client_id,
              },
            },
          });
          this.$store.dispatch("loadUsersListAction", this.userData);
        }
      }
    });

    document.addEventListener("swUpdated", this.updateAvailable, {
      once: true,
    });
    // Prevent multiple refreshes
    navigator.serviceWorker.addEventListener("controllerchange", () => {
      if (this.refreshing) return;
      this.refreshing = true;
      // Here the actual reload of the page occurs
      window.location.reload();
    });
  },
});
