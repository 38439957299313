import Vue from "vue";
import Vuex from "vuex";
import router from "@/router";
import db from "@/firebase/db";
import fb from "firebase/app";
import * as _ from "lodash";
import DbHelper from "@/tscript/dbHelper";
import { getRightWhere } from "@/tscript/utils";
import workflowStore from "./workflow";
import datacatlogStore from "./datacatlog";
import alertStore from "./alert";
import appsStore from "./apps";
import cascadeStore from "./cascade";

import Cookies from "js-cookie";

import { IUser } from "../../shared/types/user";
import { IClient } from "../../shared/types/client";
import { isStaging } from "@/services/gcloud-functions/functions";

Vue.use(Vuex);
const dbHelper = new DbHelper(db);

export default new Vuex.Store({
  modules: {
    workflowStore: workflowStore,
    datacatlogStore: datacatlogStore,
    alertStore: alertStore,
    cascadeStore: cascadeStore,
    appsStore: appsStore,
  },

  state: {
    userId: null,
    userData: null,
    userList: null,
    tagsList: null,
    eventsList: null,
    client: null,
    status: null,
    error: null,
    isAppLoading: false,
    isBackgroundLoading: false,
    backgroundLoadingText: "",
    showWorkflowDialog: false,
    displayAlertBar: false,
    notificationsCount: 0,
  },
  mutations: {
    setUserId(state: any, payload) {
      state.userId = payload;
    },

    setUserData(state: any, payload) {
      state.userData = payload;
    },

    setTagData(state: any, payload) {
      state.tagList = payload;
    },

    setUsersList(state: any, payload) {
      state.userList = payload;
    },

    setClient(state: any, payload) {
      state.client = payload;
    },

    removeUser(state: any) {
      state.userData = null;
    },

    setStatus(state: any, payload) {
      state.status = payload;
    },

    setError(state: any, payload) {
      state.error = payload;
    },

    toggleWorkflowDialog(state: any, payload) {
      state.showWorkflowDialog = payload;
    },

    toggleLoading(state: any, payload) {
      state.isAppLoading = payload;
    },

    toggleBGLoading(state: any, payload) {
      state.isBackgroundLoading = payload.state;
      state.backgroundLoadingText = payload.text;
    },

    toggleNotificationBar(state: any, payload) {
      state.displayAlertBar = payload;
    },

    setNotificationsCount(state: any, payload) {
      state.notificationsCount = payload;
    },

    closeSnackBar(state: any) {
      state.toaster.show = false;
    },
  },
  getters: {
    status(state: any) {
      return state.status;
    },
    userData(state: any) {
      return state.userData;
    },
    userId(state: any) {
      return state.userId;
    },
    error(state: any) {
      return state.error;
    },
    showWorkflowDialog(state: any) {
      return state.showWorkflowDialog;
    },
  },
  actions: {
    reloadUserAction({ commit }, payload) {
      if (payload && payload.uid) {
        commit("setUserId", payload.uid);
        this.dispatch("loadUserDataAction", payload.uid);
      }
    },

    async loadUsersListAction({ commit }, payload) {
      const user: any = payload;
      if (user?.client_id) {
        const arrayWhere = getRightWhere(user);
        const result = await dbHelper.getAllDataFromCollectionWithWhereArray(
          "users",
          arrayWhere
        );
        let idx = 0;
        for (const u of result) {
          u.name = u.first_name + " " + u.last_name;
          result[idx] = u;
          idx++;
        }
        commit("setUsersList", result);
      }
    },

    async loadUserDataAction({ commit }, payload) {
      if (payload) {
        commit("toggleLoading", true);
        const userResult: any = await dbHelper.getDocFromCollection(
          "users",
          payload
        );

        if (userResult) {
          let clientData: any | null = null;
          let siteData: any = null;
          if (userResult?.client_id) {
            clientData = await dbHelper.getDocFromCollection(
              "clients",
              userResult?.client_id
            );
            if (clientData) {
              if (userResult.site) {
                siteData = await dbHelper.getDocFromCollection(
                  `clients/${userResult?.client_id}/sites`,
                  userResult?.site
                );
              } else {
                // TODO: alert that user has no site
              }

              userResult.client = clientData;
              userResult.siteData = siteData;
              commit("setClient", clientData);
              setupCrisp(userResult, clientData, siteData);
            }

            const tagsResult = await dbHelper.getAllDataFromCollection(
              `clients/${userResult?.client_id}/tags`
            );
            commit("setTagData", tagsResult);
          }
          setFbCookie();

          commit("setUserData", userResult);
          commit("setStatus", "success");
          commit("setError", null);
        } else {
          commit("setUserData"), null;
          commit("setStatus", "failure");
        }
        commit("toggleLoading", false);
      }
    },

    logOutAction({ commit }) {
      fb.auth()
        .signOut()
        .then((response) => {
          commit("setUserId", null);
          commit("setStatus", "success");
          commit("setError", null);
        })
        .catch((error) => {
          commit("setStatus", "failure");
          commit("setError", error.message);
        });
    },

    async getTags({ commit, dispatch, state }) {
      if (state.client?.id) {
        const tagsResult = await dbHelper.getAllDataFromCollection(
          `clients/${state.client?.id}/tags`
        );
        commit("setTagData", tagsResult);
      }
    },
  },
});

// setup crisp data
function setupCrisp(userData, clientData, siteData) {
  (window as any).$crisp.push(["set", "user:email", [userData?.email]]);
  (window as any).$crisp.push([
    "set",
    "user:avatar",
    [userData?.avatarUrl || ""],
  ]);

  (window as any).$crisp.push([
    "set",
    "user:nickname",
    [`${userData?.first_name} ${userData?.last_name}`],
  ]);

  (window as any).$crisp.push([
    "set",
    "user:company",
    [
      clientData?.name || "",
      {
        description: siteData?.name || "",
        employment: [userData?.specialty, userData?.role],
      },
    ],
  ]);
}

const setFbCookie = async () => {
  let token = await fb.auth().currentUser!.getIdToken(false);
  if (process.env.NODE_ENV === "production")
    Cookies.set("firebase-auth", token, {
      domain: isStaging() ? ".staging.niagara.tech" : ".app.niagara.tech",
    });
  else Cookies.set("firebase-auth", token);
  setInterval(async () => {
    token = await fb.auth().currentUser!.getIdToken(false);
    if (process.env.NODE_ENV === "production")
      Cookies.set("firebase-auth", token, {
        domain: isStaging() ? ".staging.niagara.tech" : ".app.niagara.tech",
      });
    else Cookies.set("firebase-auth", token);
  }, 60000);
};
