import db from "@/firebase/db";
import { vuexfireMutations, firestoreAction } from "vuexfire";

const alertStore = {
  state: () => ({
    notifications: new Array(),
    toaster: {
      show: false,
    },
  }),

  actions: {
    bindNotifications: firestoreAction(
      ({ rootState, bindFirestoreRef }: any) => {
        return bindFirestoreRef(
          "notifications",
          db
            .collection("alerts")
            .doc(rootState.userId)
            .collection("notifications")
        );
      }
    ),
  },

  mutations: {
    deleteNotification(state: any, nId: string) {
      const notifIndex = state.notifications.findIndex((n: any) => {
        return n.id === nId;
      });
      if (notifIndex !== -1) state.notifications.splice(notifIndex, 1);
    },

    markAsRead(state: any, nId: string) {
      const notifIndex = state.notifications.findIndex((n: any) => {
        return n.id === nId;
      });
      if (notifIndex !== -1) state.notifications[notifIndex].read = true;
    },

    toggleSnackBar(state: any, payload: boolean) {
      state.toaster.show = payload;
    },

    ...vuexfireMutations,
  },
};

export default alertStore;
