import firebase from "firebase/app";
import "firebase/auth";
require("firebase/functions");
require("firebase/storage");
require("firebase/analytics");
require("firebase/performance");

import { FIREBASE_CONFIG } from "@/config/index";

const firebaseApp = firebase.initializeApp(FIREBASE_CONFIG);
export const perf = firebase.performance();
export const fbAnalytics = firebase.analytics();
export const storage = firebase.storage();

export default firebaseApp;
