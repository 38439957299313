export const DOMAIN_URL = "https://demo.niagara.tech";
export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyCAvsErDO0R5rLIjtB30HocfN2IuD8JmJI",
  authDomain: "niagara-demo-cbd1f.firebaseapp.com",
  databaseURL: "https://niagara-demo-cbd1f.firebaseio.com",
  projectId: "niagara-demo-cbd1f",
  storageBucket: "niagara-demo-cbd1f.appspot.com",
  messagingSenderId: "930819604450",
  appId: "1:930819604450:web:342e5929e8a62c036bc3bb",
  measurementId: "G-V0ZG03JT47",
  zone: "europe-west1",
};