import Vue from "vue";
import App from "./App.vue";
import "windi.css";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import globalfilters from "@/filters/globalfilter"; // vue filters
import vuetify from "./plugins/vuetify";

import i18n from "./i18n";
import fb from "@/firebase/init";
import "firebase/auth";

import Toasted from "vue-toasted";
import FlowyPlugin from "@niagara/cascade";
import DemoBlock from "@/components/Flowy/FlowyBlock.vue";
import DemoNode from "@/components/Flowy/FlowyNode.vue";
import VueFileAgent from "vue-file-agent";

import "@niagara/cascade/dist/lib/niagara-cascade.css";
import "vue-file-agent/dist/vue-file-agent.css";
import "vue-prism-editor/dist/prismeditor.min.css"; // import the styles somewhere
import "prismjs/components/prism-core";
import "prismjs/components/prism-sql.js";
import "prismjs/themes/prism.css";
import "splitpanes/dist/splitpanes.css";

import VueCompositionAPI from "@vue/composition-api";
import Vuelidate from "vuelidate";
import { onError } from "./managers/errorLoggingManager";
import initAcl from "./acl";
import Rollbar from "rollbar";
import { VERSION } from "./config/version";
import { getEnvironment } from "./utils";
import { Colors } from "@/utils/colors";

import "./mixins/global/vars";

Vue.config.productionTip = false;

Vue.use(globalfilters);

Vue.use(FlowyPlugin);

Vue.component("demo-block", DemoBlock);
Vue.component("demo-node", DemoNode);

Vue.use(VueCompositionAPI);

Vue.use(VueFileAgent);
Vue.use(Vuelidate);

Vue.use(Toasted, {
  className: "niag-toast text-body-1",
  containerClass: "niag-toast-container text-body-1",
  duration: 3000,
  position: "bottom-center",
});
Vue.prototype.$rollbar = new Rollbar({
  accessToken: "67947279fe8b42e3bd7369bc4df1f7c1",
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: getEnvironment(),
    code_version: VERSION,
  },
});
Vue.prototype.$colors = Colors.colors;
Vue.config.errorHandler = (error, vm, info) => {
  onError(error, null);
  vm.$rollbar.error(error);
};

const user = () => store.state.userData;
initAcl(user, router, store.state); // initialise ACL

let app: any = null;
fb.auth().onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      router,
      store,
      i18n,
      vuetify,
      render: (h) => h(App),
    });
    app.$mount("#app");
  }
});
