const prod = {
  generateCascade: "https://generate-cascade-xueygkdziq-ew.a.run.app",
};

const staging = {
  generateCascade: "https://generate-cascade-buspj6atzq-ew.a.run.app",
};

let fns = staging;

// FIXME: for staging env rather than url
export function isStaging() {
  const subdomain = window.location.hostname.split(".")[0];
  if (["staging", "localhost"].includes(subdomain)) return true;
  else return false;
}

if (!isStaging) fns = prod;

export { fns };
