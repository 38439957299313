































































































































































































































































































import Vue from "vue";
import db from "@/firebase/db";
import DBHelper from "@/tscript/dbHelper";
import BaseNoContent from "./Global/BaseNoContent.vue";
import { MAINBus, MAINEvents } from "@/events/mainBus";

export default Vue.extend({
  components: { BaseNoContent },
  props: {
    notifications: {
      type: Array,
      default: () => [] as any,
    },
  },

  computed: {
    readNotifications(): any[] {
      if (this.notifications)
        return this.notifications.filter((n: any) => n.read === true);
      else return [];
    },

    drawer: {
      get() {
        return this.$store.state.displayAlertBar;
      },
      set(nv) {
        this.$store.commit("toggleNotificationBar", nv);
      },
    },

    unreadNotifications(): any[] {
      if (this.notifications)
        return this.notifications.filter((n: any) => n.read === false);
      else return [];
    },
  },
  data() {
    return {};
  },

  methods: {
    getUser(uid: string) {
      if (this.$store.state.userList)
        return this.$store.state.userList.find((u: any) => u.id === uid);
      else return {};
    },

    updateStore() {
      this.$store.commit(
        "setNotificationsCount",
        this.unreadNotifications.length
      );
    },

    openNotif(id: string, link: string) {
      try {
        new DBHelper(db).updateDataToCollection(
          `alerts/${this.$store.state.userId}/notifications`,
          id,
          { read: true }
        );
        window.open(link, "_blank");
        this.$store.commit("markAsRead", id);
      } catch (err) {
      } finally {
      }
    },
    onNotificationDelete(item: any) {
      new DBHelper(db)
        .deleteData(`alerts/${this.$store.state.userId}/notifications`, item.id)
        .then(() => {
          const notifIndex = this.notifications.findIndex((n: any) => {
            return n.id === item.id;
          });
          // if (notifIndex !== -1) this.notifications.splice(notifIndex, 1);
        });
    },
    async markAllAsRead() {
      await new DBHelper(db).batchUpdate(
        `alerts/${this.$store.state.userId}/notifications`,
        { read: true }
      );
    },
  },
  created() {
    MAINBus.$on(MAINEvents.notifBar, () => (this.drawer = true));
  },
});
