/* eslint-disable no-console */
export default class loggerHelper {
  static report(e: any) {
    if (e) {
      console.log(e);
    }
  }
  static log(...args: any) {
    if (args) {
      console.log(...args);
    }
  }
}
