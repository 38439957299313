import Vue from "vue";
import { format, parseISO } from "date-fns";
const { acronym } = require("to-acronym");
import formatRelative from "date-fns/formatRelative";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import parse from "date-fns/parse";

import { fr } from "date-fns/locale";

import { VueConstructor } from "vue/types/umd";
import { mapState } from "vuex";

export default {
  install(Vue: VueConstructor) {
    Vue.filter("avatarize", function (responsable: string): string {
      if (responsable) {
        return responsable
          .match(/(\b\S)?/g)!
          .join("")
          .match(/(^\S|\S$)?/g)!
          .join("")
          .toUpperCase();
      }

      return "";
    });

    Vue.filter("acronymize", function (text: string): string {
      if (text) {
        const txt = text.replace(/\w\S*/g, (w) =>
          w.replace(/^\w/, (c) => c.toUpperCase())
        );
        if (text.split(" ").length === 1)
          return text.substring(0, 4).toUpperCase();
        else return acronym(txt, { highPriorityOnly: true });
      }

      return "";
    });

    Vue.filter("beautifyDate", function (date: number | Date): string {
      /* if (date) {
        return format(date, "PPp", {
          locale: fr,
        });
      } */
      return "";
    });

    Vue.filter("dateToHuman", function (date: number | Date): string {
      if (date) {
        return format(date, "Pp", {
          locale: fr,
        });
      }
      return "";
    });

    Vue.filter("relativeDate", function (date: any): string {
      if (date) {
        return formatRelative(
          parse(date, "T", new Date(), {
            locale: fr,
          }),
          new Date(),
          {
            locale: fr,
          }
        );
      }
      return "";
    });

    Vue.filter("timeStampToNow", function (date: any): string {
      if (date) {
        return formatDistanceToNow(parse(date, "T", new Date()), {
          locale: fr,
        });
      }
      return "";
    });

    Vue.filter("secondsToDate", function (seconds: any): string {
      if (seconds) {
        const s = new Date(parseInt(seconds) * 1000);
        return format(s, "Pp", {
          locale: fr,
        });
      }
      return "";
    });

    Vue.filter("distanceToNow", function (seconds: any): string {
      if (seconds) {
        const s = new Date(parseInt(seconds) * 1000);
        return formatDistanceToNow(s, { locale: fr });
      }
      return "";
    });

    Vue.filter("fDate", function (date: string): string {
      if (date) {
        return format(parseISO(date), "dd/MM/yyyy");
      }
      return date;
    });

    Vue.filter("formatNum", function (currency: number): number | string {
      const curr = `${currency}`;
      if (currency && curr !== "---") {
        return new Intl.NumberFormat("fr-FR", {
          maximumFractionDigits: 1,
        }).format(parseFloat(curr));
      }
      return currency;
    });
    Vue.filter("millisToDuration", msToTime);
  },
};

export const msToTime = function (ms: number) {
  let seconds = Math.floor(Math.round(ms) / 1000),
    minutes = Math.floor(seconds / 60),
    hours = Math.floor(minutes / 60),
    days = Math.floor(hours / 24),
    months = Math.floor(days / 30);
  const years = Math.floor(days / 365);

  seconds %= 60;
  minutes %= 60;
  hours %= 24;
  days %= 30;
  months %= 12;

  seconds = seconds < 10 ? +"0" + seconds : seconds;

  if (months > 0) {
    return months + "m" + " " + days + "j";
  } else if (months == 0 && days > 0) {
    return days + "j" + " " + hours + "h";
  } else if (days == 0 && hours > 0) {
    return hours + "h" + " " + minutes + "min";
  } else if (hours == 0 && minutes > 0) {
    return minutes + "min" + " " + seconds + "s";
  } else if (minutes == 0 && seconds > 0) {
    return seconds + "s";
  }
  if (ms < 1000) {
    ms = Math.trunc(ms);
    return "0." + (ms < 10 ? `00${ms}` : ms < 100 ? `0${ms}` : ms) + " s";
  }
};
