











import Vue from "vue";

export default Vue.extend({
  name: "AvatarPicture",
  props: {
    firstName: {
      type: String,
      default: "",
    },
    lastName: {
      type: String,
      default: "",
    },
    pictureUrl: {
      type: String,
    },
    size: {
      type: [String, Number],
    },
  },
  data() {
    return {
      sizePicture: 30,
      classInitial: "headline",
    };
  },
  methods: {
    loadData() {
      if (this.size) {
        this.sizePicture = parseInt(this.size as any);
        if (this.size < 50) {
          this.classInitial = "";
        }
      }
    },
  },
  computed: {},
  created() {
    this.loadData();
  },
});
