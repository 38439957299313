const datacatlogStore = {
  state: () => ({
    catalog: new Array(),
    nextPageToken: "",
  }),

  actions: {},

  mutations: {
    clearDCState(state: any) {
      state.catalog = [];
    },
    updateCatalog(state: any, dataCatalog: []) {
      state.catalog = dataCatalog;
    },
    pushDataToCatalog(state: any, data: []) {
      state.catalog.push(...data);
    },
    setNextPageToken(state: any, nextPageToken: string) {
      state.nextPageToken = nextPageToken;
    },
    updateDescription(state: any, payload: any) {
      const { name, desc } = payload;
      const i = state.catalog.findIndex((x: any) => x.name === name);
      if (i !== -1) {
        state.catalog[i].description = desc;
      }
    },
    updateDisplayName(state: any, payload: any) {
      const { name, displayName } = payload;
      const i = state.catalog.findIndex((x: any) => x.name === name);
      if (i !== -1) {
        state.catalog[i].displayName = displayName;
      }
    },
  },
};

export default datacatlogStore;
