import Vue from "vue";
import Vuetify from "vuetify/lib";
import "@mdi/font/css/materialdesignicons.css";

Vue.use(Vuetify);

export default new Vuetify({
  customVariables: ["~/assets/variables.scss"],
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      // strictly follows our design system guidelines
      // https://www.figma.com/file/gG1MItjybV3Xeey20HPYhr/Niagara-V1.2?node-id=6738%3A152556
      // from base to lighten 3 is the base color
      // backgrounds are lighten 4
      // colored texts are darken 2 and 3
      // gray text is darken 4
      // in case you need to use them in a different manner, reference -> ../../tailwind.config.js
      light: {
        info: "#2196F3",
        primary: {
          base: "#384FFF",
          darken1: "#0017c5",
          darken2: "#0022C5",
          darken3: "#000EA9",
          darken4: "#00008D",
          lighten1: "#0038E2",
          lighten2: "#8681FF",
          lighten3: "#A79BFF",
          lighten4: "#C7B6FF",
        },
        secondary: {
          base: "#29E1FB",
          darken1: "#00C5DE",
          darken2: "#00A9C2",
          darken3: "#008EA7",
          darken4: "#00748D",
          lighten1: "#5AFEFF",
          lighten2: "#7EFFFF",
          lighten3: "#A0FFFF",
          lighten4: "#C0FFFF",
        },
        gray: {
          base: "#9E9E9E",
          darken1: "#757575",
          darken2: "#616161",
          darken3: "#424242",
          darken4: "#212121",
          lighten1: "#BDBDBD",
          lighten2: "#E0E0E0",
          lighten3: "#EEEEEE",
          lighten4: "#F0F0F0",
          lighten5: "#F6F6F6",
        },
        error: {
          lighten5: "#FFDCCA",
          lighten4: "#FFBFAF",
          lighten3: "#FFA194",
          lighten2: "#FF847A",
          lighten1: "#FF6761",
          base: "#FD4949",
          darken1: "#DD2632",
          darken2: "#BC001C",
          darken3: "#9C0006",
          darken4: "#7E0000",
        },
        warning: {
          lighten3: "#FFFFCE",
          lighten4: "#FFFCB2",
          lighten2: "#FFDF96",
          lighten1: "#FFC37C",
          base: "#FFA862",
          darken1: "#E08D49",
          darken2: "#C27430",
          darken3: "#A45B16",
          darken4: "#874300",
        },
        success: {
          lighten4: "#BAFFFF",
          lighten3: "#9BFFFF",
          lighten2: "#7CFFEB",
          lighten1: "#5BF1CE",
          base: "#36D4B3",
          darken1: "#00B898",
          darken2: "#009C7E",
          darken3: "#008265",
          darken4: "#00684D",
        },
      },
    },
  },
});
