import Joi from "joi";
import db from "@/firebase/db";
import { vuexfireMutations, firestoreAction } from "vuexfire";
import { AddedFilter } from "../../shared/types/app";
import router from "@/router";

const idSchema = Joi.object({
  label: Joi.string().required(),
  description: Joi.string().required(),
  structure: Joi.object().required(),
});

const templateSchema = Joi.object({
  formula: Joi.string().required(),
  filters: Joi.object().required(),
});

const advancedSchema = Joi.object({
  dataset: Joi.object().required(),
  formula: Joi.array().min(1).required(),
  // objectiveType: Joi.string().required(),
});

const recapSchema = Joi.object({
  filters: Joi.array().items(
    Joi.object({
      column: Joi.string(),
      filter: Joi.string().when("column", {
        is: Joi.not().exist(),
        then: Joi.required(),
      }),
      inputField1: Joi.string().required().allow(null),
      inputField2: Joi.string().required().allow(null),
      datePicker1: Joi.string().required().allow(null),
      datePicker2: Joi.string().required().allow(null),
      timePicker1: Joi.string().required().allow(null),
      timePicker2: Joi.string().required().allow(null),
    })
      .or(
        "inputField1",
        "inputField2",
        "datePicker1",
        "datePicker2",
        "timePicker1",
        "timePicker2"
      )
      .unknown(true)
  ),
});

const appsStore = {
  state: () => ({
    stepper: 0,
    openedStepper: false,

    form: {
      label: "",
      description: "",
      structure: null,
      owner: null,
      clientId: null,
      createdBy: null,
      createdOn: new Date(),
      modifiedOn: new Date(),
      settings: {
        temporality: "day",
        objectiveType: null,
        query: null,
        formula: null,
        unit: "",
      } as any,
      formulaQuery: {
        filters: null,
        formula: null,
        statement: null,
        table: null,
      },
    },
    meta: {
      formula: null,
      dataset: null,
      filters: null,
    },
    currentApp: null,
  }),

  mutations: {
    updateAppCreation(state: any, payload: any) {
      const { key, value } = payload;
      state.form[key] = value;
    },
    updateAppFormulaQuery(state: any, payload: any) {
      const { key, value } = payload;
      state.form.formulaQuery[key] = value;
    },
    updateAppSettings(state: any, payload: any) {
      const { key, value } = payload;
      state.form.settings[key] = value;
    },
    updateAppMeta(state: any, payload: any) {
      const { key, value } = payload;
      state.meta[key] = value;
    },
    updateValidation(state: any, payload: any) {
      const { key, value } = payload;
      state.validation[key] = value;
    },
    updateAppStepper(state: any, payload: number) {
      state.stepper = payload;
    },
    toggleAppStepper(state: any, payload: boolean) {
      state.openedStepper = payload;
    },

    setCurrentApp(state: any, payload: any) {
      state.currentApp = payload;
    },
    resetAppForm(state: any) {
      state.stepper = 0;
      state.form = {
        label: "",
        description: "",
        structure: null,
        owner: null,
        clientId: null,
        createdBy: null,
        createdOn: new Date(),
        modifiedOn: new Date(),
        settings: {
          temporality: "day",
          objectiveType: null,
          query: null,
          formula: null,
          unit: "",
        } as any,
        formulaQuery: {
          filters: null,
          formula: null,
          statement: null,
          table: null,
        },
      };

      state.meta = {
        formula: null,
        dataset: null,
        filters: null,
      };
    },
    ...vuexfireMutations,
  },

  actions: {},

  getters: {
    isAppIdentificationValid(state: any) {
      const { label, description, structure } = state.form;
      const { value, error } = idSchema.validate({
        label,
        description,
        structure,
      });
      if (error) {
        return false;
      }
      return true;
    },
    isDataStepValid(state: any) {
      if (state.stepper === 2) {
        const { value, error } = advancedSchema.validate({
          dataset: state.meta.dataset,
          formula: state.meta.formula,
        });
        if (error) {
          return false;
        }
        return true;
      }
      if (state.stepper === 3) {
        const { value, error } = templateSchema.validate({
          formula: state.form.formulaQuery.formula,
          filters: state.form.formulaQuery.filters,
        });
        if (error) {
          return false;
        }
        return true;
      }
    },
    isRecapStepValid(state: any) {
      const filters: AddedFilter[] =
        state.form.formulaQuery.filters.addedFilters;
      const { error } = recapSchema.validate({ filters });
      if (error) return false;
      /* const objectiveType = state.form.settings.objectiveType;
      const { value, error } = recapSchema.validate({
        objectiveType,
      });
      if (error) {
        return false;
      } */
      return true;
    },
  },
};

export default appsStore;
