import { render, staticRenderFns } from "./TheNotificationSideBar.vue?vue&type=template&id=2d5b5205&scoped=true&"
import script from "./TheNotificationSideBar.vue?vue&type=script&lang=ts&"
export * from "./TheNotificationSideBar.vue?vue&type=script&lang=ts&"
import style0 from "./TheNotificationSideBar.vue?vue&type=style&index=0&id=2d5b5205&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d5b5205",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VAvatar,VBtn,VCard,VIcon,VList,VNavigationDrawer,VTab,VTabItem,VTabs})
