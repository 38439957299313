import { groupBy } from "lodash";

export function dateToCronJob(days: number[], times: string[]): string[] {
  const cronJobs: string[] = [];
  times.forEach((item) => {
    const timeArray = item.split(":");
    cronJobs.push(`${timeArray[1]} ${timeArray[0]} * * ${days.join(",")}`);
  });
  return cronJobs;
}

export const checkSingleConditionFn = (
  condition: Record<string, string>,
  calculatedValue: number | string
) => {
  let passes = false;
  try {
    const cond = eval(
      `${Number(calculatedValue)}${condition.value}${Number(condition.cValue)}`
    );
    passes = cond;
  } catch (e) {
    passes = false;
  }
  return passes;
};

export const checkConditionFn = (
  subConditions: any[],
  calculatedValue: number | string
) => {
  const conditionsArray: boolean[] = [];
  let passes = false;
  try {
    subConditions.forEach((x: any) => {
      const cond = eval(
        `${Number(calculatedValue)}${x.value}${Number(x.cValue)}`
      );
      conditionsArray.push(cond);
    });
    const met = !conditionsArray.includes(false);
    passes = met;
  } catch (e) {
    passes = false;
  }
  return passes;
};

export const conditionsCheck = (
  conds: any[],
  kpi: any,
  app: any
): Record<string, boolean> => {
  const conditions = groupBy(conds, "formula");
  const isOkValues: any = {};

  const dateIsBetween = (date: Date, min: Date, max: Date) =>
    date.getTime() >= min.getTime() && date.getTime() <= max.getTime();

  for (const [key, value] of Object.entries(conditions)) {
    if (kpi.calculatedValue !== undefined && kpi.conditions.length > 0) {
      const timeType = app.settings.temporality;
      const objType = app.settings.objectiveType;
      const calculatedValue = kpi.calculatedValue.find(
        (x: any) => x.name === key
      )?.value;
      if (calculatedValue !== undefined) {
        // fixed objective type
        if (objType === "fixed") {
          isOkValues[key] = checkConditionFn(value, calculatedValue);
        }
        if (
          objType === "linear" &&
          (timeType === "week" || timeType === "week-1")
        ) {
          // multiply every condition value in this.conditions by 1/7 and use it in checkConditionFn
          /* const newConditions = conditions.map((x: any) => {
        return { ...x, cValue: x.cValue * (1 / 7) };
      }); 
      console.log(newConditions);
      */
          isOkValues[key] = checkConditionFn(value, calculatedValue);
        }
        if (
          objType === "linear" &&
          (timeType === "month" || timeType === "month-1")
        ) {
          // multiply every condition value in this.conditions by 1/30 and use it in checkConditionFn
          /* const newConditions = conditions.map((x: any) => {
        return { ...x, cValue: x.cValue * (1 / 30) };
      });
      console.log(newConditions); */
          isOkValues[key] = checkConditionFn(value, calculatedValue);
        }
        if (objType === "gradual") {
          const objectives = app.settings.objectives;
          if (objectives.length > 0) {
            const serverTime = new Date();
            const month = serverTime.getMonth();
            const year = serverTime.getFullYear();
            if (
              dateIsBetween(
                serverTime,
                new Date(year, month, 0),
                new Date(year, month, 7)
              )
            ) {
              const newConditions = value.map((x: any) => {
                return {
                  ...x,
                  cValue: x.cValue * (Number(objectives[0]) / 100),
                };
              });
              isOkValues[key] = checkConditionFn(
                newConditions,
                calculatedValue
              );
            }
            if (
              dateIsBetween(
                serverTime,
                new Date(year, month, 7),
                new Date(year, month, 14)
              )
            ) {
              const newConditions = value.map((x: any) => {
                return {
                  ...x,
                  cValue: x.cValue * (Number(objectives[1]) / 100),
                };
              });
              isOkValues[key] = checkConditionFn(
                newConditions,
                calculatedValue
              );
            }
            if (
              dateIsBetween(
                serverTime,
                new Date(year, month, 14),
                new Date(year, month, 22)
              )
            ) {
              const newConditions = value.map((x: any) => {
                return {
                  ...x,
                  cValue: x.cValue * (Number(objectives[2]) / 100),
                };
              });
              isOkValues[key] = checkConditionFn(
                newConditions,
                calculatedValue
              );
            }
            if (
              dateIsBetween(
                serverTime,
                new Date(year, month, 22),
                new Date(year, month + 1, 1)
              )
            ) {
              const newConditions = value.map((x: any) => {
                return {
                  ...x,
                  cValue: x.cValue * (Number(objectives[3]) / 100),
                };
              });
              isOkValues[key] = checkConditionFn(
                newConditions,
                calculatedValue
              );
            }
          }
          return isOkValues;
        }
      }
    }
  }

  return isOkValues;
};

export const getEnvironment = () => {
  const subdomain = window.location.hostname.split(".")[0];
  if (subdomain === "localhost") return "local";
  if (subdomain === "staging") return "staging";
  return "production";
};
