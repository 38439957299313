import { fbAnalytics } from "@/firebase/init";
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import fb from "firebase/app";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/Home.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/settings",
    name: "settings",
    component: () => import("@/views/Settings.vue"),
    meta: {
      requiresAuth: true,
      can: "super",
    },
  },
  {
    path: "/parameters",
    name: "parameters",
    component: () => import("@/views/Parameters.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/niagara-workflows",
    name: "niagara-workflows",
    component: () => import("@/views/NiagaraWorkflows.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/Login.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/user-profile/:id",
    name: "user-profile",
    component: () => import("@/views/UserProfile.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("@/views/Profile.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/workflow/:id",
    name: "workflow",
    component: () => import("@/views/Workflow.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/workflow-nodes/:id",
    name: "workflow-nodes",
    component: () => import("@/views/WorkflowViews/N8NWFDetail.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/systems",
    name: "systems",
    component: () => import("@/views/Systems.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/systems/:label",
    name: "system-detail",
    component: () => import("@/views/SystemViews/SystemDetail.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/apps",
    name: "apps",
    component: () => import("@/views/Apps.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/apps/:id",
    name: "apps-detail",
    component: () => import("@/views/AppsViews/AppDetail.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/apps/:id/:kpiId",
    name: "kpis-detail",
    component: () => import("@/views/AppsViews/KpiDetail.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/data-catalog",
    name: "data-catalog",
    component: () => import("@/views/DataCatalog.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/data-catalog/:entryId",
    name: "data-catalog-detail",
    component: () => import("@/views/DataCatalogViews/DataCatalogDetail.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/dashboard/",
    name: "dashboard",
    component: () => import("@/views/ComingSoon.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/workflows/",
    name: "workflows",
    component: () => import("@/views/Workflows.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/calculation-models/",
    name: "calculation-models",
    component: () => import("@/views/ComingSoon.vue"),
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.afterEach((to, from) => {
  fbAnalytics.logEvent("page_view", {
    page_path: to.path,
    page_name: to.name,
    page_location: to.fullPath,
  });
});

router.beforeEach((to, from, next) => {
  const user = fb.auth().currentUser;
  const redirectFrom = to.path !== "/login" ? to.path : "/";
  if (to.name !== "login" && !user)
    next({
      name: "login",
      query: { redirectFrom },
    });
  else next();
});

export default router;
