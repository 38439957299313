

































































































































































































































































import Vue from "vue";
import AvatarPicture from "@/components/Global/AvatarPicture.vue";
import { MAINBus, MAINEvents } from "@/events/mainBus";

export default Vue.extend({
  name: "Sidebar",
  components: {
    AvatarPicture,
  },
  data() {
    return {
      userData: null,
      langs: Array(),
      drawer: false,
      group: null,
      mini: false,
    };
  },
  computed: {
    workflows() {
      return this.$store.state.client?.parameters?.workflows?.active;
    },
    systems() {
      return this.$store.state.client?.parameters?.systems?.active;
    },
    apps() {
      return this.$store.state.client?.parameters?.apps?.active;
    },
    datacatalog() {
      return this.$store.state.client?.parameters?.data_catalog?.active;
    },
    client() {
      let client = this.$store.state.client;
      if (client) {
        if (client.parameters) return client;
        else {
          client.parameters.data_catalog.status = "";
          client.parameters.systems.status = "";
          client.parameters.workflows.status = "";
          return client;
        }
      }
      return client;
    },
    user() {
      return this.$store.state.userData;
    },
  },
  methods: {
    goTo(to: string) {
      const router: any = this.$router;
      const currentRouteName = router?.history.current.name;

      if (currentRouteName != to) {
        this.$router.push({ name: to });
      }
    },
    openNotifications() {
      this.$store.commit("toggleNotificationBar", true);
    },
    logout() {
      this.$store.dispatch("logOutAction").then(() => {
        this.$router.push({ name: "login" });
      });
    },
  },
  watch: {
    group() {
      this.drawer = false;
    },
  },
});
