































import Vue from "vue";

export default Vue.extend({
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: "mdi-water-off-outline",
    },
    description: {
      type: String,
    },
    buttonText: {
      type: String,
    },
    disabledButton: {
      type: Boolean,
      default: false,
    },
  },
});
