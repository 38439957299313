export const Colors = {
  colors: {
    gray: {
      base: "#9E9E9E",
      darken1: "#757575",
      darken2: "#616161",
      darken3: "#424242",
      darken4: "#212121",
      ligthen1: "#BDBDBD",
      ligthen2: "#E0E0E0",
      ligthen3: "#EEEEEE",
      ligthen4: "#F0F0F0",
      ligthen5: "#F6F6F6",
      white: "#FFFFFF",
    },
    primary: {
      0: "#EBEDFF",
      50: "#CAD1FF",
      100: "#AAB4FF",
      150: "#8998FF",
      200: "#687BFF",
      300: "#3249FE",
      400: "#0823F3",
      500: "#0017C5",
      600: "#001298",
      700: "#000C6A",
      800: "#00073D",
      900: "#00020F",
    },
    secondary: {
      0: "#EBFCFF",
      50: "#B8F3FD",
      100: "#87EAFC",
      150: "#57E2FA",
      200: "#28DAF8",
      300: "#0DB9D7",
      400: "#009BB6",
      500: "#007F94",
      600: "#006273",
      700: "#004652",
      800: "#002931",
      900: "#000D0F",
    },
    accent: {
      0: "#F3EBFF",
      50: "#E3CBFF",
      100: "#D3ABFF",
      150: "#C38BFF",
      200: "#B26AFF",
      300: "#8F34FF",
      400: "#7309FA",
      500: "#5900CB",
      600: "#44009C",
      700: "#30006D",
      800: "#1B003E",
      900: "#07000F",
    },
    error: {
      0: "#FFF5F5",
      50: "#FFD7D7",
      100: "#FFB9B9",
      150: "#FF9B9B",
      200: "#FF7D7D",
      300: "#FD4949",
      400: "#D52929",
      500: "#AD1616",
      600: "#850B0B",
      700: "#5D0606",
      800: "#350303",
      900: "#0D0101",
    },
    success: {
      0: "#F0FFFC",
      50: "#CAF9EF",
      100: "#A5F3E2",
      150: "#83EDD7",
      200: "#62E7CB",
      300: "#36D4B3",
      400: "#2AB396",
      500: "#23927B",
      600: "#1D7260",
      700: "#175144",
      800: "#0F3029",
      900: "#060F0D",
    },
    warning: {
      0: "#FFF5F0",
      50: "#FFDDCD",
      100: "#FFC6A9",
      150: "#FFAE85",
      200: "#FF9660",
      300: "#FF702D",
      400: "#D75E25",
      500: "#AF4E20",
      600: "#873F1D",
      700: "#5F2F18",
      800: "#371D11",
      900: "#0F0906",
    },
    rose: {
      0: "#FFF0F9",
      50: "#FFD0ED",
      100: "#FFB0E1",
      150: "#FF90D4",
      200: "#FF6FC8",
      300: "#FE3FAD",
      400: "#D63191",
      500: "#AE2876",
      600: "#87225C",
      700: "#5F1B42",
      800: "#371227",
      900: "#0F060B",
    },
    jaune: {
      0: "#FFFCE5",
      50: "#FFF8C3",
      100: "#FFF496",
      150: "#FFEF69",
      200: "#FFEA3A",
      300: "#FFD800",
      400: "#D7B705",
      500: "#AF960C",
      600: "#7F6E10",
      700: "#5F5413",
      800: "#37310F",
      900: "#0F0E06",
    },
  },
};
