const workflowStore = {
  state: () => ({
    source: "",
    destination: "Excel",
    trigger: "MANUAL",
    areas: [],
    inputModel: "",
    inputModelOriginFileName: "",
    typeChoice: "",
    template: "",
    name: "",
    description: "",
    tags: [],
    toUpload: {
      files: [],
      batch: {},
    },
  }),

  actions: {},

  mutations: {
    filesToUpload(state: any, { files, batch }: { files: any; batch: any }) {
      state.toUpload.files = files;
      state.toUpload.batch = batch;
    },
    updateInputModelOriginFileName(state: any, fileName: string) {
      state.inputModelOriginFileName = fileName;
    },
    updateSource(state: any, source: string) {
      state.source = source;
    },
    updateTrigger(state: any, trigger: string) {
      state.trigger = trigger;
    },
    updatewfTypeChoice(state: any, type: string) {
      state.typeChoice = type;
    },
    updatewfTemplate(state: any, type: string) {
      state.template = type;
    },
    updateAreas(state: any, fields: any) {
      const defaultPDF = [
        {
          name: "Date",
          type: "date",
          format: "dd.mm.yyyy hh:mm",
          required: false,
          area: [132.759375, 320.0604015350342, 150.609375, 439.0604015350342],
        },
        {
          name: "Modele",
          type: "field",
          regex: "",
          required: false,
          area: [18.221875, 176.640625, 40.534375000000004, 558.184375],
        },
        {
          name: "N\u00b0 lot",
          type: "field",
          regex: "\\d*",
          required: false,
          area: [110.446875, 175.77290153503418, 129.784375, 307.4166515350342],
        },
        {
          name: "N\u00b0 OF",
          type: "field",
          regex: "\\d*",
          required: true,
          area: [43.509375, 175.15312500000002, 64.33437500000001, 314.234375],
        },
      ];

      if (state.source == "PDF") {
        state.areas = [defaultPDF, fields].flat();
      } else {
        state.areas = fields;
      }
    },
    updateInputModel(state: any, file: any) {
      state.inputModel = file;
    },
    updateIdentification(state: any, id: any) {
      state.name = id.name;
      state.description = id.description;
      state.tags = id.tags;
    },
  },

  getters: {
    getSource(state: any) {
      const sourceMapping: any = { Excel: ".xlsx, .xls, .xlsm", PDF: ".pdf" };
      return sourceMapping[state.source];
    },
  },
};

export default workflowStore;
